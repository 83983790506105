import { isBlockquote, isCode, isHeading, renderRule } from 'datocms-structured-text-utils';
import { GatsbyImage } from "gatsby-plugin-image";
import React from "react";
import { renderMarkRule, StructuredText } from "react-datocms";
import CodeBlock from './codeBlock';

export default function PostBody({ content }) {
  return (
    <div className="max-w-4xl mx-auto">
      <div className="prose prose-lg prose-blue text-white max-w-none">
        <StructuredText
          data={content}
          renderBlock={({ record }) => {
            if (record.__typename === "DatoCmsImageBlock") {
              return <div className="flex justify-center">

                <GatsbyImage image={record.image.gatsbyImageData} />
              </div>
            }
            return (
              <>
                <p>Forgot to handle rendering this type of block 😅</p>
                <pre>{JSON.stringify(record, null, 2)}</pre>
              </>
            );
          }}
          customNodeRules={[
            renderRule(
              isHeading,
              ({ node, adapter: { renderNode }, children, key }) => {
                return renderNode(`h${node.level}`, {
                  key, className: 'text-light-blue font-bold underline underline-offset-4 text-2xl sm:text-3xl'
                }, children);
              },
            ),
            renderRule(isBlockquote, ({ node, children, key }) => {
              return (
                <div
                  key={key}
                  className="text-left relative w-full mx-auto before:absolute before:left-0 before:right-0 before:top-0 before:bg-white before:w-2 before:h-full"
                >
                  <div className="text-xl ml-8 text-white">{children}</div>
                </div>
              );
            }),
            renderRule(isCode, ({ node, key }) => {
              return (
                <CodeBlock
                  key={key}
                  code={node.code}
                  language={node.language || 'unknown'}
                  showLineNumbers
                />
              );
            })
          ]}
          customMarkRules={[
            // convert "strong" marks into <b> tags
            renderMarkRule('strong', ({ mark, children, key }) => {
              return <span key={key} className="font-bold">{children}</span>;
            }),
            renderMarkRule('highlight', ({ mark, children, key }) => {
              return <span key={key} className="text-black bg-yellow-200">{children}</span>;
            }),
            renderMarkRule('code', ({ mark, children, key }) => {
              return <span key={key} className="font-mono tracking-tight text-sm p-1 bg-gray-100 text-black">{children}</span>;
            }),
          ]}

        />
      </div>
    </div>
  );
}



import React from "react";
import Highlight, { defaultProps } from "prism-react-renderer";
import oceanicNext from "prism-react-renderer/themes/oceanicNext";


export default function CodeBlock({ code, language, showLineNumbers }) {
  return (
    <Highlight
      {...defaultProps}
      code={code}
      language={language}
      theme={oceanicNext}
    >
      {({ className, style, tokens, getLineProps, getTokenProps }) => (
        <pre className={className} style={style}>
          {tokens.map((line, i) => (
            <div {...getLineProps({ line, key: i })}>
              {showLineNumbers && (
                <span className="text-right pr-6 select-none opacity-40 min-w-64 table-cell">{i + 1}</span>
              )}
              <span className="table-cell">
                {line.map((token, key) => (
                  <span {...getTokenProps({ token, key })} />
                ))}
              </span>
            </div>
          ))}
        </pre>
      )}
    </Highlight>
  );
};